import { createRouter, createWebHashHistory } from "vue-router";
import * as actions from "@/store/actions";
import * as effects from "@/store/effects";
import * as state from "@/store/state";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: () => import("@/pages/Home"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/pages/Settings"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ventures",
    name: "Idea and Ventures",
    component: () => import("@/pages/Ventures"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/venture/:ventureId/detail",
    name: "Venture Dashboard",
    component: () => import("@/pages/VentureDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/venture/:ventureId/target-customers",
    name: "Venture Target Customers",
    component: () => import("@/pages/VentureTargetCustomers"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/venture/:ventureId/contacts",
    name: "Venture Contacts",
    component: () => import("@/pages/VentureContacts"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/venture/:ventureId/interviews",
    name: "Venture Interviews",
    component: () => import("@/pages/VentureInterviews"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/venture/:ventureId/contacts/:id",
    name: "Venture Contact Detail",
    component: () => import("@/pages/ContactDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/venture/:ventureId/opportunities",
    name: "Venture Opportunities",
    component: () => import("@/pages/VentureOpportunities"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/venture/:ventureId/opportunity/:id",
    name: "Venture Opportunity Detail",
    component: () => import("@/pages/OpportunityDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecosystem/:companyId/opportunity/:id",
    name: "Ecosystem Opportunity Detail",
    component: () => import("@/pages/OpportunityDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/venture/:ventureId/resources",
    name: "Venture Resources",
    component: () => import("@/pages/VentureResources"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/venture/:ventureId/settings",
    name: "Venture Settings",
    component: () => import("@/pages/VentureSettings"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/venture/:ventureId/survey-data",
    name: "Venture Survey Data",
    component: () => import("@/pages/VentureSurveyData"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/venture/:ventureId/target-customers/:id",
    name: "Venture Target Customer Detail",
    component: () => import("@/pages/TargetCustomerDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/venture-team",
    name: "Venture Team",
    component: () => import("@/pages/VentureTeam"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecosystem/:companyId/detail",
    name: "Ecosystem Detail",
    component: () => import("@/pages/Ecosystem"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecosystem/:companyId/programs",
    name: "Ecosystem Programs",
    component: () => import("@/pages/EcosystemPrograms"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecosystem/:companyId/venture-teams",
    name: "Ecosystem Venture Teams",
    component: () => import("@/pages/EcosystemVentureTeams"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecosystem/:companyId/venture-teams/:id",
    name: "Ecosystem Venture Team Detail",
    component: () => import("@/pages/EcosystemVentureTeamDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecosystem/:companyId/people",
    name: "Ecosystem People",
    component: () => import("@/pages/EcosystemPeople"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecosystem/:companyId/opportunities",
    name: "Ecosystem Opportunities",
    component: () => import("@/pages/EcosystemOpportunities"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecosystem/:companyId/resources",
    name: "Ecosystem Resources",
    component: () => import("@/pages/EcosystemResources"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecosystem/:companyId/settings",
    name: "Ecosystem Settings",
    component: () => import("@/pages/EcosystemSettings"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("@/pages/Contacts"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contact/detail",
    name: "Contact Detail",
    component: () => import("@/pages/ContactDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/venture/:ventureId/contact/:id",
    name: "Contact Detail",
    component: () => import("@/pages/ContactDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/opportunity/detail",
    name: "Opportunity Detail",
    component: () => import("@/pages/OpportunityDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecosystem/:companyId/people/:id",
    name: "Ecosystem People Detail",
    component: () => import("@/pages/PeopleDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecosystem/:companyId/cohort/:id",
    name: "Ecosystem Cohort Detail",
    component: () => import("@/pages/CohortDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecosystem/:companyId/program/:id",
    name: "Ecosystem Program Detail",
    component: () => import("@/pages/ProgramDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecosystem/:companyId/program/:programId/cohort/:id",
    name: "Ecosystem Program Cohort Detail",
    component: () => import("@/pages/CohortDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/opportunities",
    name: "Opportunities",
    component: () => import("@/pages/Opportunities"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/resources",
    name: "Resources",
    component: () => import("@/pages/Resources"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/resources/detail/:id",
    name: "Resource Detail",
    component: () => import("@/pages/ResourceDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecosystem/:companyId/resources/:id",
    name: "Ecosystem Resource Detail",
    component: () => import("@/pages/ResourceDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/venture/:ventureId/resources/:id",
    name: "Venture Resource Detail",
    component: () => import("@/pages/ResourceDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/register-user",
    name: "Register",
    component: () => import("@/pages/Register"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/pages/Login"),
  },
  {
    path: "/register-company",
    name: "Register Company",
    component: () => import("@/pages/RegisterCompany"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setup-payment",
    name: "Setup Payment",
    component: () => import("@/pages/SetupPayment"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/invite-people",
    name: "Invite People",
    component: () => import("@/pages/InvitePeople"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: () => import("@/pages/ResetPassword"),
  },
  {
    path: "/reset-password-email-sent",
    name: "Reset Password Success",
    component: () => import("@/pages/ResetPasswordSuccess"),
  },
  {
    path: "/reset-password/:token/:email",
    name: "New Password",
    component: () => import("@/pages/NewPassword"),
  },
  {
    path: "/verify-email/:token/:email",
    name: "Verify Email",
    component: () => import("@/pages/VerifyEmail"),
    meta: {},
  },
  {
    path: "/verify-email-step",
    name: "Verify Email Step",
    component: () => import("@/pages/VerifyEmailStep"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/demographic-information",
    name: "Demographic Information",
    component: () => import("@/pages/DemographicInformation"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/interest-activity",
    name: "Interest And Activity",
    component: () => import("@/pages/InterestAndActivity"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/test",
    name: "Test Page",
    component: () => import("@/pages/TestPage"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/survey/:id",
    name: "Survey",
    component: () => import("@/pages/Survey"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

router.beforeEach(async (to, from, next) => {
  const IsItABackButton = window.popStateDetected;
  window.popStateDetected = false;
  if (
    to.name === "Register Company" ||
    to.name === "Setup Payment" ||
    to.name === "Invite People" ||
    to.name === "Survey"
  ) {
    next();
    return false;
  }
  const derivedState =
    from &&
    from.matched &&
    from.matched[0] &&
    from.matched[0]["instances"] &&
    from.matched[0]["instances"]["default"]
      ? from.matched[0]["instances"]["default"]["state"]
      : state;
  console.log(to, from);
  const homeRoute = "Dashboard";
  const loginRoute = "Login";
  if (to.matched.some((routeRecord) => routeRecord.meta.requiresAuth)) {
    if (!derivedState.currentUser?.id) {
      const { token, userId } = await actions.getStoredAuthToken();
      if (token && userId) {
        await actions.loginWithToken({ state, effects }, { token, userId });
        actions.loginWithTokenHeavy({ state, effects }, { token, userId });
      }
    }
    if (
      to?.name === "Verify Email Step" &&
      !derivedState?.currentUser?.isLoggedIn
    ) {
      next();
      return false;
    }
    if (
      !derivedState.currentUser?.id ||
      (derivedState?.currentUser?.id &&
        !derivedState.currentUser?.emails[0].verified)
    ) {
      next({ name: loginRoute });
    } else {
      // if (!derivedState.currentUser?.gender) {
      //   next({name: 'Demographic Information'});
      //   return false;
      // }
      // if (derivedState.currentUser?.tags?.filter(t => t?.type === 'DESCRIPTORS_DESCRIPTORS')?.length === 0) {
      //   next({name: 'Interest And Activity'});
      //   return false;
      // }
      next();
    }
  } else {
    if (!derivedState.isLoggedIn) {
      const { token, userId } = await actions.getStoredAuthToken();
      if (token && userId) {
        await actions.loginWithToken({ state, effects }, { token, userId });
        actions.loginWithTokenHeavy({ state, effects }, { token, userId });
      }
    }
    if (derivedState.isLoggedIn) {
      const derivedActions =
        from &&
        from.matched &&
        from.matched[0] &&
        from.matched[0]["instances"] &&
        from.matched[0]["instances"]["default"] &&
        from.matched[0]["instances"]["default"]["actions"];
      if (derivedActions && IsItABackButton) {
        try {
          if (to?.name === "Register") {
            await localStorage.setItem(
              "register_params",
              JSON.stringify(to?.query)
            );
          }
          // if (derivedActions.user.inviteUserAction) {
          //   await derivedActions.user.inviteUserAction({
          //     route: to,
          //     user: derivedState.currentUser,
          //     noAlert: true,
          //   });
          // } else {
          //   await actions.inviteUserAction({
          //     route: to,
          //     user: derivedState.currentUser,
          //     noAlert: true,
          //   });
          // }
        } catch (e) {
          console.log(e);
        }
        window.history.go(-1);
        // derivedActions.alert.showError({
        //   message: "You're already logged in. You should logout first!",
        // });
      } else {
        if (!derivedState?.currentUser?.emails?.[0]?.verified) {
          next({ name: loginRoute });
          return false;
        } else {
          try {
            if (to?.name === "Register") {
              await localStorage.setItem(
                "register_params",
                JSON.stringify(to?.query)
              );
            }
            // if (derivedActions?.user?.inviteUserAction) {
            //   await derivedActions.user.inviteUserAction({
            //     route: to,
            //     user: derivedState.currentUser,
            //     noAlert: true,
            //   });
            // } else {
            //   await actions.inviteUserAction({
            //     route: to,
            //     user: derivedState.currentUser,
            //     noAlert: true,
            //   });
            // }
          } catch (e) {
            console.log(e);
          }
          if (
            derivedState.currentUser?.groups?.find(
              (g) => g?.name === "ecosystem-owner"
            )
          ) {
            if (!derivedState.currentUser?.company?.id) {
              next({ name: "Register Company" });
              return false;
            }
            if (derivedState.currentUser?.paymentMethods?.length === 0) {
              next({ name: "Setup Payment" });
              return false;
            }
          } else {
            if (
              derivedState.currentUser?.id &&
              derivedState.currentUser?.tags?.length === 0
            ) {
              next({ name: "Demographic Information" });
              return false;
            }
            if (
              derivedState.currentUser?.id &&
              derivedState.currentUser?.tags?.filter(
                (t) => t?.category === "DESCRIPTORS"
              )?.length === 0 &&
              derivedState.currentUser?.id &&
              derivedState.currentUser?.tags?.filter(
                (t) => t?.category === "DESCRIPTORS"
              )?.length === 0
            ) {
              next({ name: "Interest And Activity" });
              return false;
            }
          }
          next({ name: homeRoute, query: to?.query });
        }
      }
    } else {
      next();
    }
  }
});

export default router;
